<template>
  <div v-if="orderPrice.order_once_pay && orderPrice.order_once_pay.price && !orderTotalDaysValidation">
    <div>
      <b-form-group class="payment-types mb-2">
        <div class="payment-header d-flex align-items-center mb-2">
          <b-icon class="icon-card highlighted-text mr-3" icon="credit-card" />
          Vyberte spôsob platby
        </div>
        <b-card
          v-if="orderPrice.order_monthly_pay.max_partial_payments > 1"
          class="rounded-0 border-0 mb-2 payment-type z-clickable"
          :class="{ selected: orderIsPaymentSelected && orderIsPartialPayment }"
          @click="orderIsPartialPayment = true"
        >
          <div>
            <div class="d-flex w-100 align-items-center">
              <div class="d-flex flex-column w-100 align-items-center">
                <div class="w-100 border-bottom pb-2">
                  <div>
                    <div class="row align-items-center mb-2">
                      <div class="col-5">
                        <h6 class="font-weight-bold mb-2">
                          Platba <br />mesačne
                          <z-tooltip
                            text="
                            V ponuke si môžete zvoliť počet splátok a platiť po čiastkach. Celkovú hodnotu objednávky
                            Vám rovnomerne rozpočítame a čiastkové zálohové faktúry Vám budeme posielať v mesačných intervaloch."
                          />
                        </h6>
                      </div>
                      <div class="col-5 d-flex flex-column align-items-end">
                        <div>
                          <div class="price">{{ orderPrice.order_monthly_pay.price_monthly }}€</div>
                          <div class="space">
                            <span class="month-note">/mesiac</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="radio mb-2"><div></div></div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between text-small">
                      <div>Celková hodnota objednávky</div>
                      <div class="font-weight-bold">{{ orderPrice.order_monthly_pay.price }}€</div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center w-100 pt-2">
                  <div
                    class="col-9 p-0 text-small"
                    :class="{ 'highlighted-select-text': !orderPartialPayments && orderIsPartialPayment }"
                  >
                    Vyberte si počet mesiacov platby:
                  </div>
                  <div class="col-3 p-0">
                    <b-form-select
                      v-model="orderPartialPayments"
                      :class="{ 'highlighted-select': !orderPartialPayments && orderIsPartialPayment }"
                      @change="changeMonths"
                    >
                      <b-form-select-option
                        v-for="index in parseInt(orderPrice.order_monthly_pay.max_partial_payments) - 1"
                        :key="index"
                        :value="index + 1"
                      >
                        {{ index + 1 }}
                      </b-form-select-option>
                    </b-form-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card>
        <b-card
          class="rounded-0 border-0 payment-type z-clickable"
          :class="{ selected: orderIsPaymentSelected && !orderIsPartialPayment }"
          @click="orderIsPartialPayment = false"
        >
          <div>
            <div class="d-flex w-100 align-items-center">
              <div class="d-flex flex-column w-100 align-items-center">
                <div class="w-100">
                  <div class="row align-items-center">
                    <div class="col-5">
                      <h6 class="font-weight-bold">
                        Platba jednorázovo
                        <span v-if="showDiscount" class="highlighted-text">(zvýhodnená)</span>
                      </h6>
                    </div>
                    <div class="col-5 font-weight-bold text-small text-right">
                      <div v-if="showDiscount">
                        Ušetríte
                        <span class="font-weight-bold">{{ orderPrice.order_monthly_pay.partial_payment_fee }}€</span>
                      </div>
                      <div class="d-flex flex-column align-items-end">
                        <div class="price">
                          {{ orderPrice.order_once_pay.price.toFixed(2).replace(/[.,]00$/, "") }}€
                        </div>
                        <div class="full-price icon-strikethrough" v-if="showDiscount">
                          <s
                            ><span class="font-weight-bold">{{ orderPrice.order_monthly_pay.total_price }}€</span></s
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="radio"><div></div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import { mapFields } from "vuex-map-fields"

export default {
  components: {
    "z-tooltip": () => import("@/plugins/app@components/tooltip/z-tooltip.vue"),
  },

  mounted() {
    if (this.$route.query.platba) {
      const paymentType = this.$route.query.platba

      if (paymentType == "jednorazovo") {
        this.orderIsPartialPayment = false
      }

      if (paymentType == "mesacne") {
        this.orderIsPartialPayment = true
      }
    }
  },
  methods: {
    singleLessonPrice(price) {
      return Math.round(price / this.totalDays)
    },

    changeMonths() {
      this.$store.dispatch("order/getNewPrices")
    },
  },

  computed: {
    ...mapFields("order", {
      orderPartialPayments: "partialPayments",
      orderIsPartialPayment: "isPartialPayment",
    }),
    ...mapState("order", ["orderPrice", "orderRegistrations"]),
    ...mapGetters("order", {
      orderIsPaymentSelected: "isPaymentSelected",
      orderTotalDaysValidation: "totalDaysValidation",
    }),

    totalDays() {
      return this.orderRegistrations.reduce((totalDays, student) => {
        if (!student.order_registration_dates) return totalDays

        const daysNum = student.order_registration_dates.length
        return totalDays + daysNum
      }, 0)
    },

    totalDaysByStudent() {
      if (this.orderRegistrations)
        return this.orderRegistrations.map((student) => {
          return student.order_registration_dates.length
        })
      return 0
    },

    totalPrice() {
      return this.orderPrice.order_monthly_pay.total_price
    },

    invoicesMonthsCount() {
      return this.orderPrice.order_monthly_pay.proforma_invoices_months_count
    },

    showDiscount() {
      return false;//this.type === "gamecraft" && this.orderPrice.order_monthly_pay.max_partial_payments > 1
    },

    type() {
      return this.$route.params.type
    },
  },
}
</script>

<style lang="sass" scoped>
.payment-types
  background: #efede9

  .icon-card
    font-size: 26px

.highlighted-text
  color: #F7BE3D

.highlighted-select
  box-shadow: 0.3rem 0.3rem 0.3rem rgba(255, 0, 0, .2) !important

.highlighted-select-text
  color: rgba(255, 0, 0, .7)

.text-small
  font-size: 13px

.note
  border-left: 3px solid #81CB9F
  padding-left: 5px

.price
  font-size: 22px
  line-height: 22px
  font-weight: bold

.full-price
  font-size: 12px

.price-info
  padding-right: 95px

.space
  line-height: 12px

.month-note
  font-size: 12px
  font-weight: bold
  color: #54514B
</style>
